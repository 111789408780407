<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

// Components
import Users from './Krediflow-Settings-Users.vue'
import Channels from './Krediflow-Settings-Channels.vue'
import Bexio from './Krediflow-Settings-Bexio.vue'

const auth = useAuthStore()

const channel = ref(localStorage.getItem('krediflow_channel'))
const channels = ref([])

// on page load...
onMounted( async () => {
    // Get Inbox Data
    console.log("init_data", channel.value)
    init_data()
})

const init_data = async () => {
    channel.value = localStorage.getItem('krediflow_channel')
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-user-channels")
        .then(response => {
            channels.value = response.data.channels
            if (channel.value == null) {
                channel.value = response.data.channels[0].id
                if (!localStorage.getItem('krediflow_channel')) {
                    localStorage.setItem('krediflow_channel', channel.value)
                }
            }
    })
}

const setChannel = (e) => {
    localStorage.setItem('krediflow_channel', e.value)
    init_data()
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <div>
        <Toolbar v-if="channels.length > 1" class="m-2">
            <template #start>
                <span class="mr-2 font-bold">Mandat</span>
                <Dropdown @change="setChannel" v-model="channel" :options="channels" optionLabel="name" optionValue="id" />
            </template>
        </Toolbar>
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-cog mr-2"></i>
                    <span class="mr-3">Bexio</span>
                </template>
                <Bexio :prop_channel="channel" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-user mr-2"></i>
                    <span class="mr-3">Benutzer</span>
                </template>
                <Users :prop_channel="channel" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-users mr-2"></i>
                    <span class="mr-3">Rollen</span>
                </template>
            hello
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-inbox mr-2"></i>
                    <span class="mr-3">Eingangsrechnungen</span>
                </template>
            hello
            </TabPanel>
            <TabPanel v-if="channel == 'krediflow'">
                <template #header>
                    <i class="pi pi-exclamation-triangle mr-2"></i>
                    <span class="mr-3">Mandaten</span>
                </template>
                <Channels v-if="channel == 'krediflow'" />
            </TabPanel>
        </TabView>
    </div>
</template>