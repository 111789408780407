
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const channel = ref()
const channels = ref([])
const auth = useAuthStore()

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_channels = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const new_channel_id = ref()
const new_channel_name = ref()
const edit_channel_id = ref()
const edit_channel_name = ref()
const edit_channel_subscription = ref()
const createChannelDialog = ref(false)
const editChannelDialog = ref(false)

const subscriptions = ref([
    {
        "id": "Free",
        "name": "Gratis",
    },
    {
        "id": "Standard Bexio",
        "name": "Bexio Standard (CHF 29.00/Monat)",
    }
])

onMounted( async () => {
    init_data()
})

const init_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/krediflow/get-channels')
    .then(response => {
        channels.value = response.data
    })
    loader.value = false
}

const create_channel = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/krediflow/check-channel', {id: new_channel_id.value})
    .then(response => {
        if(response.data.status == "success"){
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/krediflow/create-channel', {id: new_channel_id.value, name: new_channel_name.value})
            .then(response => {
                toast.add({severity:'success', summary: 'Mandat erstellt', detail: 'Das Mandat wurde erfolgreich erstellt.', life: 5000})
                init_data()
                createChannelDialog.value = false
            })
        } else {
            toast.add({severity:'error', summary: 'Mandat existiert bereits', detail: 'Das Mandat existiert bereits.', life: 5000})
        }
    })
}

const edit_channel = (e) => {
    console.log("edit channel", e)
    edit_channel_id.value = e.data.id
    edit_channel_name.value = e.data.name
    edit_channel_subscription.value = e.data.subscription
    editChannelDialog.value = true
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <div>
        <Toast />
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-3">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_channels['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
                <Button label="Mandat erstellen" icon="pi pi-plus" class="p-button" @click="createChannelDialog = true" />
            </template>
        </Toolbar>
        <DataTable class="mt-3" v-model:filters="filter_channels" :value="channels" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="ID"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="subscription" header="Abo"></Column>
            <Column header="Zahlungsmethode"></Column>
            <Column header="Bexio"></Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="edit_channel(slotProps)" />
                </template>
            </Column>
        </DataTable>
        <!------------------------------------------------------->
        <!------------------ Create Channel --------------------->
        <!------------------------------------------------------->
        <Dialog v-model:visible="createChannelDialog" :style="{width: '700px'}" header="Mandat erstellen" :modal="true" class="p-fluid z-5">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="channel_id" type="text" v-model="new_channel_id" />
                        <label for="channel_id">ID</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="channel_name" type="text" v-model="new_channel_name" />
                        <label for="channel_name">Namen</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <div>
                        <Button :disabled="new_channel_id ? false : true" label="Mandat erstellen" @click="create_channel()" class="w-auto mr-2 mb-1 p-button" icon="pi pi-save" />
                        <Button label="Abbrechen" @click="inviteUserDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                    </div>
                </div>
            </div>
        </Dialog>
        <!----------------------------------------------------->
        <!------------------ Edit Channel --------------------->
        <!----------------------------------------------------->
        <Dialog v-model:visible="editChannelDialog" :style="{width: '700px'}" header="Mandat editieren" :modal="true" class="p-fluid z-5">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="channel_id" type="text" v-model="edit_channel_id" />
                        <label for="channel_id">ID</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="channel_name" type="text" v-model="edit_channel_name" />
                        <label for="channel_name">Namen</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <Dropdown v-model="edit_channel_subscription" :options="subscriptions" optionValue="id" optionLabel="name" placeholder="Abo" />
                </div>
                <div class="field col-12 mt-3">
                    <div>
                        <Button :disabled="new_channel_id ? false : true" label="Mandat erstellen" @click="create_channel()" class="w-auto mr-2 mb-1 p-button" icon="pi pi-save" />
                        <Button label="Abbrechen" @click="inviteUserDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>