<script setup>
import { ref, onMounted, watch } from 'vue'
//import { useToast } from 'primevue/usetoast'
//import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
//import { FilterMatchMode } from 'primevue/api'
//import { useAuthStore } from '@/core/store/AuthStore'
import { useCustomerStore } from '@/core/store/CustomerStore';
import '@/core/assets/primevue/neuraxis.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token = ref(false)
const user_email = ref(false)
const user_firstname = ref("")
const user_lastname = ref("")
const user_password = ref("")
const user_password_repeat = ref("")
const password_too_weak = ref(false)

const keep_logged_in = ref(true);
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const loading = ref(true)
const button_loading = ref(false);

onMounted(() => {

    const tkn = getQueryParam('token');
    token.value = tkn;
    if (token.value) {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/dashboard/invite-token', { token: token.value, channel: appmode.customer })
            .then(response => {

                if (response.data.message == 'success') {
                    user_email.value = response.data.user_email
                    loading.value = false;
                }
                if (response.data.message == 'missmatch') {
                    loading.value = false;
                }

            })
    }
})

const register_ = async () => {
    const isPasswordValid = computed(() => {
        const hasLowerCase = /[a-z]/.test(user_password.value);
        const hasUpperCase = /[A-Z]/.test(user_password.value);
        const hasNumber = /[0-9]/.test(user_password.value);
        const hasSpecialChar = /[^a-zA-Z\d]/.test(user_password.value);

        return (
            user_password.value === user_password_repeat.value &&
            user_password.value.length > 8 &&
            hasLowerCase &&
            hasUpperCase &&
            hasNumber &&
            hasSpecialChar
        );
    });

    if (!isPasswordValid.value) {
        password_too_weak.value = true;
        return;
    }

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/register`,
            {
                token: token.value,
                user_email: user_email.value,
                user_firstname: user_firstname.value,
                user_lastname: user_lastname.value,
                user_password: user_password.value,
            }
        );

        if (response.data.message === 'success') {
            setTimeout(() => {
                window.location.href = '/'
            }, 3000);
        }
    } catch (error) {
        console.error('Error registering:', error);
    }
}

const register = () => {
    button_loading.value = true
    if (user_password.value != user_password_repeat.value || user_password.value.length <= 8 || user_password.value.match(/[a-z]/g) == null || user_password.value.match(/[A-Z]/g) == null || user_password.value.match(/[0-9]/g) == null || user_password.value.match(/[^a-zA-Z\d]/g) == null) {
        password_too_weak.value = true;
        return;
    }
    const workload = {
        token: token.value,
        user_email: user_email.value,
        user_firstname: user_firstname.value,
        user_lastname: user_lastname.value,
        user_password: user_password.value,
        channel: appmode.customer
    }

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/register`, workload)
        .then(response => {
            if (response.data.message == 'success') {
                setTimeout(() => {
                    button_loading.value = true
                    window.location.href = '/'
                }, 3000)
            }
        })
}
const getLogo = () => {
    return require(`@/customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}
</script>


<template>
    <div class="flex justify-center items-center h-full" v-if="loading">
        <LoadingSpinner size="16" />
    </div>
    <div class="flex justify-center mt-6" v-else>
        <div class="bg-white p-4 shadow-md rounded w-full lg:w-3/4">
            <div class="text-center mb-5">
                <img class="mx-auto h-12 w-auto " :src="getLogo()" alt="" />
                <div class="text-black text-3xl font-medium mb-3">Snappy Dashboard</div>

                <span v-if="user_email" class="text-gray-600 font-medium leading-relaxed">Sie wurden in <span class="text-primary-500">{{ appmode.fullname }}</span> Snappy-Instanz eingeladen!</span>
                <span v-if="!user_email" class="text-red-500 font-medium leading-relaxed">Dieser Einladungslink ist abgelaufen oder nicht mehr gültig!</span>
            </div>

            <div v-if="user_email">
                <label for="email" class="block text-black font-medium mt-2">Email</label>
                <InputText disabled id="email" v-model="user_email" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="firstname" class="block text-black font-medium mt-2">Vornamen</label>
                <InputText id="firstname" v-model="user_firstname" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="lastname" class="block text-black font-medium mt-2">Nachnamen</label>
                <InputText id="lastname" v-model="user_lastname" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="password" class="block text-black font-medium mt-2">Passwort</label>
                <InputText id="password" v-model="user_password" type="password" class="w-full mt-3 border p-2 rounded" />
                <small v-if="password_too_weak" class="text-red-500">Passwort zu schwach</small>

                <label for="password_repeat" class="block text-black font-medium mt-2">Passwort wiederholen</label>
                <InputText id="password_repeat" v-model="user_password_repeat" type="password" class="w-full mt-3 border p-2 rounded" />

                <Button :disabled="user_email && user_firstname && user_lastname && user_password && user_password_repeat && user_password == user_password_repeat ? false : true" @click="register" label="Registrieren" icon="pi pi-check" class="w-full mt-4 bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded" :loading="button_loading" />
            </div>
        </div>
    </div>
</template>
