import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon,
} from '@heroicons/vue/24/outline';

export default [{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
{ name: 'Krediflow', href: '/dashboard/Krediflow', icon: InboxIcon, current: false, order: 1 },
{ name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 3 },
{ name: 'Add-User', href: '/dashboard/add-user', icon: UserGroupIcon, current: false, order: 4 },
{ name: 'Hilfe', href: '#', icon: MegaphoneIcon, current: false, order: 5 },
{ name: 'Logout', href: '/logout', icon: ArrowLeftOnRectangleIcon, current: false, order: 6 }];