<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

// Components
import Workflow from './Krediflow-Workflow.vue'

////////////
// System //
////////////

const channel = ref(localStorage.getItem('krediflow_channel'))
const channel_name = ref(localStorage.getItem('krediflow_channel_name'))
const channels = ref([])

//////////////////
// Data Sources //
//////////////////

const inbox = ref([])
const krediflow_inbox = ref([])
const krediflow_archive = ref([])
const krediflow_cancelled = ref([])
const krediflow_thumb_index = ref(0)
const users = ref([])
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_inbox = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_bexio_inbox = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const workflow_tabs = ref([])
const context_menu = ref()
const channel_menu_items = ref([])
const settingsDialog = ref(false)
const context_menu_param = ref()
const context_menu_users = ref()
const context_menu_items = ref([])
const downloadBexioInboxDialog = ref(false)
const uploadPdfDialoag = ref(false)
const bexio_inbox = ref([])
const inbox_section = ref("inbox")
const active_workflow = ref("inbox")

// on page load...
onMounted( async () => {
    // Get Inbox Data
    init_data()
})

const init_data = async () => {
    channel.value = localStorage.getItem('krediflow_channel')
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-user-channels")
        .then(response => {
            channels.value = response.data.channels
            for (let key in channels.value)
                channel_menu_items.value.push({
                label: channels.value[key].name,
                icon: 'pi pi-sign-in',
                command: (e) => setChannel({value: channels.value[key].id})
                })
            if (channel.value == null) {
                channel.value = response.data.channels[0].id
                if (!localStorage.getItem('krediflow_channel')) {
                    localStorage.setItem('krediflow_channel', channel.value)
                    for (let key in channels.value)
                        if(channels.value[key].id == channel.value)
                            localStorage.setItem('krediflow_channel_name', channels.value[key].name)
                            channel_name.value = localStorage.getItem('krediflow_channel_name')
                }
            }
    })
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-inbox", {"channel": channel.value})
        .then(response => {
            console.log("get-inbox", response.data)
            krediflow_inbox.value = response.data.inbox
            krediflow_archive.value = response.data.archived
            krediflow_cancelled.value = response.data.cancelled
            inbox.value = krediflow_inbox.value
    })
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-users", {"channel": channel.value})
    .then(response => {
        users.value = response.data
        context_menu_items.value = [
            {
                label: 'Öffnen',
                icon: 'pi pi-fw pi-pencil',
                command: (e) => open_workflow(context_menu_param.value)
            },
            {
                label: 'Weiterleiten',
                icon: 'pi pi-fw pi-arrow-right',
                items: get_users_for_menu()
            },
            {
                label: 'Erkennung (V2)',
                icon: 'pi pi-fw pi-refresh',
                command: (e) => reset_workflow(context_menu_param.value)
            },
            {
                seperator: true
            },
            {
                label: 'Löschen',
                icon: 'pi pi-fw pi-times',
                command: (e) => cancel_workflow(context_menu_param.value)
            }
        ]
    })
}

const setChannel = (e) => {
    console.log("setChannel", e)
    localStorage.setItem('krediflow_channel', e.value)
    for (let key in channels.value)
        if(channels.value[key].id == e.value)
            localStorage.setItem('krediflow_channel_name', channels.value[key].name)
    // redirect to dashboard
    window.location.href = "/dashboard"
}

const rename_bexio_file = (e) => {
    console.log("rename_bexio_file", e)
    if (e.newValue != e.value){
        let payload = {
            "channel": channel.value,
            "id": e.data.id,
            "filename": e.newValue
        }
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/bexio-rename-file", payload)
        .then(response => {
            toast.add({severity:'success', summary: 'Datei umbenannt', detail: 'Datei wurde erfolgreich umbenannt', life: 3000})
            get_bexio_inbox()
        })
    }
}

const bexio_inbox_open = ref([])
const bexio_inbox_archived = ref([])
const bexio_inbox_section = ref("inbox")
const selected_bexio_files = ref([])
const get_bexio_inbox = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-bexio-inbox", {"channel": channel.value})
    .then(response => {
        bexio_inbox_open.value = response.data.filter(function( obj ) {return obj.is_archived !== true})
        bexio_inbox_archived.value = response.data.filter(function( obj ) {return obj.is_archived !== false})
        if (bexio_inbox_section.value == "inbox") {
            bexio_inbox.value = bexio_inbox_open.value
        }
        if (bexio_inbox_section.value == "archive") {
            bexio_inbox.value = bexio_inbox_archived.value
        }
        downloadBexioInboxDialog.value = true
        loader.value = false
    })
}

const download_file = (e) => {
    loader.value = true
    let payload = e
    console.log("download", payload)
    payload["channel"] = channel.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-bexio-invoice", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Import erfolgreich', detail: response.data.message, life: 3000})
            init_data()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht heruntergeladen werden', life: 3000})
            loader.value = false
        }
    })
}

const download_files = (event) => {
    loader.value = true
    for (let key in event){
        download_file(event[key])
    }
}

const restore_file = (e) => {
    loader.value = true
    let payload = e
    console.log("download", payload)
    payload["channel"] = channel.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/restore-bexio-invoice", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Rechnung wurde erfolgreich wiederhergestellt', detail: response.data.message, life: 3000})
            get_bexio_inbox()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht wiederhergestellt werden', life: 3000})
            loader.value = false
        }
    })
}

const restore_files = (event) => {
    loader.value = true
    for (let key in event){
        restore_file(event[key])
    }
}

const archive_file = (e) => {
    loader.value = true
    let payload = e
    console.log("download", payload)
    payload["channel"] = channel.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/archive-bexio-invoice", payload)
    .then(response => {
        if(response.data.status == "success"){
            toast.add({severity:'success', summary: 'Rechnung wurde erfolgreich archiviert', detail: response.data.message, life: 3000})
            get_bexio_inbox()
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Rechnung konnte nicht archiviert werden', life: 3000})
            loader.value = false
        }
    })
}

const archive_files = (event) => {
    loader.value = true
    for (let key in event){
        archive_file(event[key])
    }
}


///////////////
// Interface //
///////////////

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        close_tab(e.workflow_id)
    }
}

const close_tab = (workflow_id) => {
    for (let key in workflow_tabs.value){
        if (workflow_tabs.value[key]["workflow_id"] == workflow_id){
            workflow_tabs.value.splice(key, 1)
            console.log("workflow_tabs", workflow_tabs.value.length)
            if (workflow_tabs.value.length == 0) {
                active_workflow.value = "inbox"
            } else {
                active_workflow.value = workflow_tabs.value[workflow_tabs.value.length - 1]["workflow_id"]
            }
        }
    }
}

//when opening a workflow
const open_workflow = (e) => {
    context_menu.value.hide()
    let workflow_id = e.data.workflow_id
    for (let key in inbox.value){
        if (inbox.value[key].workflow_id == workflow_id) {
            let found = workflow_tabs.value.some((el) => el.workflow_id === workflow_id);
            if (!found) {
                let name = ""
                if (inbox.value[key].workflow_id > 25) {
                    name = inbox.value[key].invoice_number.substring(0, 25) + "..."
                } else {
                    name = inbox.value[key].invoice_number
                }
                workflow_tabs.value.push({
                    "workflow_id": inbox.value[key].workflow_id,
                    "name": name
                })
                active_workflow.value = inbox.value[key].workflow_id
            }
            else
            {
                active_workflow.value = e.data.workflow_id
            }
        }
    }
}

const show_context_menu = (e) => {
    context_menu.value.show(e.originalEvent)
    context_menu_param.value = e
}

const forward_workflow = (e) => {
    console.log("forward workflow", e)
}

const reset_workflow = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich die Erkennung wiederholen?',
        header: 'Erkennung wiederholen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Erkennung wiederholen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            console.log("reset workflow", e)
            let payload = {
            "workflow_id": e.data.workflow_id,
            "channel": channel.value
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/update-bexio-invoice", payload)
            .then(response => {
                if (response.data.status == "success") {
                    init_data()
                    toast.add({severity: 'success', summary: 'Erkennung wiederholen', detail: 'Erkennung wurde erfolgreich wiederholt', life: 3000})
                } else {
                    toast.add({severity: 'error', summary: 'Erkennung wiederholen', detail: 'Erkennung konnte nicht wiederholt werden', life: 3000})
                }
            })
        }
    })
}

const cancel_workflow = (e) => {
    console.log("cancel workflow", e)
    confirm.require({
        message: 'Wollen Sie wirklich den Workflow abbrechen?',
        header: 'Workflow abbrechen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Workflow abbrechen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = {
            "workflow_id": e.data.workflow_id,
            "editor": "test",
            "status": "cancelled",
            "channel": channel.value
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/cancel-workflow", payload)
            .then(response => {
                if (response.data.status == "success") {
                    init_data()
                    toast.add({severity: 'success', summary: 'Rechnung wurde abgebrochen', detail: 'Rechnung wurde erfolgreich abgebrochen', life: 3000})
                }
            })
        }
    })
}

const upload_bexio_pdf = async (e) => {
    loader.value = true;
    try {
        for (let i = 0; i < e.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[i]);
            reader.onload = async () => {
                let payload = { filename: e.files[i].name, file: reader.result, channel: channel.value};
                await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/upload-bexio-pdf", payload)
                .then(response => {
                    if (response.data.status == "success") {
                        toast.add({severity: 'success', summary: 'PDF '+e.files[i].name+' wurde hochgeladen', detail: 'PDF Datei '+e.files[i].name+' wurde erfolgreich hochgeladen', life: 3000})
                    }
                })
            }
            if (i + 1 == e.files.length) {
                loader.value = false
                uploadPdfDialoag.value = false
            }
        }
    } catch (error) {
        console.error(error);
    }
}

const channel_menu = ref()
const toggle_channel_menu = (event) => {
    channel_menu.value.toggle(event);
}


const db_menu = ref()
const toggle_db_menu = (event) => {
    db_menu.value.toggle(event);
}
const db_menu_items = ref([
    {
        items: [
            {
                label: 'Dashboard',
                icon: 'pi pi-angle-double-left',
                to: '/dashboard'
            }
        ]
    },
    { separator: true },
    {
        items: [
            {
                label: 'Posteingang',
                icon: 'pi pi-inbox',
                to: '/krediflow'
            },
            {
                label: 'Einstellungen',
                icon: 'pi pi-cog',
                to: '/dashboard/Einstellungen'
            }
        ]
    }
])

////////////////
// Formatting //
////////////////

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const get_users_for_menu = () => {
    let users_for_menu = [{label: 'Rollen', icon: 'pi pi-fw pi-users', items: []}]
    for (let key in users.value){
        users_for_menu.push({
            label: users.value[key].user_fullname,
            icon: 'pi pi-fw pi-user',
            command: (e) => forward_workflow(context_menu_param.value, users.value[key].id)
        })
    }
    return users_for_menu
}

const toggle_active_workflow = (e) => {
    if (e == "inbox") {
        active_workflow.value = "inbox"
    }
    else {
        if(workflow_tabs.value.some(obj => obj.workflow_id == e)) {
            active_workflow.value = e
        }
        else {
            if (workflow_tabs.value.length == 0){
                active_workflow.value = "inbox"
            }
        }
    }
}

const bexio_thumb_index = ref(0)

const bexio_thumb_index_change = (event) => {
    console.log("toggle_thumbnail", event)
    bexio_thumb_index.value = event.index
}
</script>

<style>
.inbox-datatable tr .meta-data {
    display: none;
}
.inbox-datatable tr:hover .meta-data {
    display: inline;
}
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ContextMenu ref="context_menu" :model="context_menu_items" />
    <Toolbar v-if="'admin-dropdown' == true">
        <template #start>
            <Button icon="pi pi-bars" class="mr-4" rounded @click="toggle_db_menu" />
            <Menu ref="db_menu" id="overlay_menu" :model="db_menu_items" :popup="true" />
            <Button rounded size="small" @click="toggle_channel_menu">
                <span class="mr-2">{{ channel_name }}</span>
                <i class="pi pi-angle-down" />
            </Button>
            <Menu ref="channel_menu" id="overlay_menu" :model="channel_menu_items" :popup="true" style="width: 300px;" />
        </template>
    </Toolbar>
    <Toolbar>
        <template #start>
            <Button rounded label="Posteingang" icon="pi pi-inbox" v-tooltip.top="'Posteingang'" @click="toggle_active_workflow('inbox')" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded v-for="(workflow_tab, key) in workflow_tabs" :key="key" @click="toggle_active_workflow(workflow_tab.workflow_id)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != workflow_tab.workflow_id}" class="mr-2"><i class="pi pi-file mr-3"/>{{workflow_tab.name}}<i class="pi pi-times-circle ml-3 text-red-100" @click="close_tab(workflow_tab.workflow_id)" /></Button>
        </template>
    </Toolbar>
        <div v-if="active_workflow == 'inbox'" class="mt-3">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_inbox['global'].value" placeholder="Suche" style="width: 300px" class="mr-4" />
                    </span>
                    <span v-if="inbox_section == 'inbox'" class="mr-4 font-bold">Offene Rechnungen<Badge :value="inbox.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'inbox'" @click="inbox_section = 'inbox', inbox = krediflow_inbox" class="mr-4 cursor-pointer">Offene Rechnungen<Badge :value="krediflow_inbox.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section == 'archive'" class="mr-4 font-bold">Erledigt<Badge :value="krediflow_archive.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'archive'" @click="inbox_section = 'archive', inbox = krediflow_archive" class="mr-4 cursor-pointer">Erledigt<Badge :value="krediflow_archive.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section == 'cancelled'" class="mr-4 font-bold">Papierkorb<Badge :value="krediflow_cancelled.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="inbox_section != 'cancelled'" @click="inbox_section = 'cancelled', inbox = krediflow_cancelled" class="mr-4 cursor-pointer">Papierkorb<Badge :value="krediflow_cancelled.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                </template>
                <template #end>
                    <Button @click="get_bexio_inbox()" v-tooltip.bottom="'Rechnungen aus der Bexio Inbox laden'" label="Bexio" class="mr-2 p-button" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-download" />
                    <Button @click="get_bexio_inbox()" v-tooltip.bottom="'Rechnungen aus der E-Mail Inbox laden'" label="E-Mails" class="mr-2 p-button" icon="pi pi-envelope" />
                    <Button @click="uploadPdfDialoag = true" v-tooltip.bottom="'Rechnungen hochladen'" label="Hochladen" class="mr-2 p-button" icon="pi pi-upload" />
                </template>
            </Toolbar>
            <!---------------------------------------------------->
            <!------------------ DataTables ---------------------->
            <!---------------------------------------------------->
            <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_inbox" :value="inbox" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}" @row-click="open_workflow" @row-contextmenu="show_context_menu" dataKey="worfklow_id">
                <Column field="preview" style="width: 10rem">
                    <template #body="slotProps">
                        <div class="overflow-hidden shadow-2 w-9rem h-4rem" @mouseover="bexio_thumb_index = slotProps.index">
                            <img :src="slotProps.data.preview" />
                        </div>
                    </template>
                </Column>
                <Column field="workflow_name" header="Belege" sortable>
                    <template #body="slotProps">
                        <div class="mt-2">
                            <Chip class="pl-0 pr-3 mr-2">
                                <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                                <span class="ml-2 font-medium">{{slotProps.data.invoice_number}}</span>
                            </Chip>
                            <span class="font-bold mr-4 white-space-nowrap">{{slotProps.data.supplier_name}}</span>
                            <Badge class="mr-2 mb-1 bg-primary text-white hidden" :value="slotProps.data.workflow_name" v-tooltip.top="'Rechnungsnummer'"></Badge>
                            <Badge class="mr-2 mb-1 bg-primary text-white" v-if="slotProps.data.invoice_amount" value="Rechnung" v-tooltip.top="'Belegtyp: Rechnung'"></Badge>
                            <Badge class="mr-2 mb-1 bg-red-500 text-white" v-if="slotProps.data.invoice_amount" :value="slotProps.data.invoice_currency + ' ' + slotProps.data.invoice_amount" v-tooltip.top="'Rechnungsbetrag'"></Badge>
                            <Badge class="mr-2 mb-1 bg-gray-100 text-black-alpha-50" v-if="slotProps.data.email_from" :value="slotProps.data.email_from" v-tooltip.top="'Absender E-Mail'"></Badge>
                            <span hidden class="text-black-alpha-50 text-sm mr-2 white-space-nowrap">{{slotProps.data.email_subject}}</span>
                        </div>
                    </template>
                </Column>
                <Column field="email_date" header="Eingang" sortable>
                    <template #body="slotProps">
                        {{swiss_date(slotProps.data.email_date)}}
                    </template>
                </Column>
                <Column field="workflow_id" hidden></Column>
                <Column field="email_subject" hidden></Column>
                <Column field="supplier_name" hidden></Column>
                <Column field="invoice_amount" hidden></Column>
                <Column field="invoice_number" hidden></Column>
                <Column field="email_from" hidden></Column>
                <Column field="status" header="Status" sortable>
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.status == 'open'" class="mr-2 mb-1 bg-primary text-white" value="Offen" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-for="(workflow_tab, key) in workflow_tabs" :key="key" :hidden="active_workflow != workflow_tab.workflow_id">
            <Workflow :channel_prop="channel" :workflow_id="workflow_tab.workflow_id" @editor-callback="editor_callback" />
        </div>

    <!------------------------------------------------->
    <!--------- Bexio Search Dialog ------------------->
    <!------------------------------------------------->
    <Dialog v-model:visible="downloadBexioInboxDialog" :style="{width: '80%'}" :header="'Bexio Posteingang'" :modal="true" class="p-fluid z-5">    
        <BlockUI :blocked="loader">
            <Toolbar>
                <template #start>
                    <InputText v-model="filter_bexio_inbox['global'].value" placeholder="Dateien durchsuchen" style="width: 300px" class="mr-4" />
                    <span v-if="bexio_inbox_section == 'inbox'" class="mr-4 font-bold">Bexio Posteingang<Badge :value="bexio_inbox_open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="bexio_inbox_section != 'inbox'" @click="bexio_inbox_section = 'inbox', bexio_inbox = bexio_inbox_open, bexio_thumb_index = 0, selected_bexio_files = []" class="mr-4 cursor-pointer">Bexio Posteingang<Badge :value="bexio_inbox_open.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="bexio_inbox_section == 'archive'" class="mr-4 font-bold">Archiviert<Badge :value="bexio_inbox_archived.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span v-if="bexio_inbox_section != 'archive'" @click="bexio_inbox_section = 'archive', bexio_inbox = bexio_inbox_archived, bexio_thumb_index = 0, selected_bexio_files = []" class="mr-4 cursor-pointer">Archiviert<Badge :value="bexio_inbox_archived.length" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'inbox'" @click="download_files(selected_bexio_files)" v-tooltip.bottom="'Dateien importieren'" icon="pi pi-download" :label="String(selected_bexio_files.length) + ' x Dateien importieren'" class="p-button w-auto mr-4" />
                    <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'inbox'" @click="archive_files(selected_bexio_files)" v-tooltip.bottom="'Dateien archivieren'" icon="pi pi-folder" :label="String(selected_bexio_files.length) + ' x Dateien archivieren'" class="p-button w-auto mr-4" />
                    <Button v-if="selected_bexio_files.length > 0 && bexio_inbox_section == 'archive'" @click="restore_files(selected_bexio_files)" v-tooltip.bottom="'Dateien in Posteingang verschieben'" icon="pi pi-refresh" :label="String(selected_bexio_files.length) + ' in Posteingang verschieben'" class="p-button w-auto" />
                </template>
            </Toolbar>
            <!--------------------------------------------------->
            <!------------------ DataTable ---------------------->
            <!--------------------------------------------------->
            <div class="flex">
                <div class="flex-auto">
                    <DataTable class="mt-3 w-full" @row-click="bexio_thumb_index_change" editMode="cell" @cell-edit-complete="rename_bexio_file" v-model:selection="selected_bexio_files"  v-model:filters="filter_bexio_inbox" :value="bexio_inbox" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                        <Column field="created_at" header="Datum" sortable style="width: 10rem">
                            <template #body="slotProps">
                                <span>{{ swiss_date_time(slotProps.data.created_at) }}</span>
                            </template>
                        </Column>
                        <Column field="preview" style="width: 10rem">
                            <template #body="slotProps">
                                <div class="overflow-hidden shadow-2 w-9rem h-4rem" @mouseover="bexio_thumb_index = slotProps.index">
                                    <img v-if="slotProps.data.preview" :src="slotProps.data.preview" />
                                </div>
                            </template>
                        </Column>
                        <Column field="name" header="Datei" sortable>
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" class="w-full" />
                            </template>
                            <template #body="{ data, field }">
                                <div class="font-bold">{{ data[field]}}</div>
                                <div v-if="data['uploader_email']">{{ data['uploader_email'] }}</div>
                            </template>
                        </Column>
                        <Column field="uploader_email" header="Von" hidden />
                        <Column field="source_type" header="Herkunft">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.source_type == 'email'">E-Mail</span>
                                <span v-if="slotProps.data.source_type == 'web'">Upload</span>
                            </template>
                        </Column>
                        <Column field="extension">
                            <template #body="slotProps">
                                <Button v-if="bexio_inbox_section == 'inbox'" :disabled="selected_bexio_files.length > 0" @click="download_file(slotProps.data)" v-tooltip.bottom="slotProps.data.extension.toUpperCase() + ' importieren'" icon="pi pi-download" class="p-button mr-2 mt-1" />
                                <Button v-if="bexio_inbox_section == 'inbox'" :disabled="selected_bexio_files.length > 0" @click="archive_file(slotProps.data)" v-tooltip.bottom="'Datei archivieren'" icon="pi pi-folder" class="p-button mr-2 mt-1" />
                                <Button v-if="bexio_inbox_section == 'archive'" :disabled="selected_bexio_files.length > 0" @click="restore_file(slotProps.data)" v-tooltip.bottom="'In Posteingang verschieben'" icon="pi pi-refresh" class="p-button" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div v-if="bexio_inbox" class="flex-auto p-2" style="width: 450px">
                    <img v-if="bexio_inbox.length > 0" :src="bexio_inbox[bexio_thumb_index].preview" class=" shadow-3" />
                </div>
            </div>
        </BlockUI>
    </Dialog>
    <!-------------------------------------------------->
    <!-------------- PDF Upload Dialog ----------------->
    <!-------------------------------------------------->
    <Dialog header="PDF hochladen" v-model:visible="uploadPdfDialoag" :modal="true" class="">
        <div class="">
            <div class="">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_bexio_pdf" :multiple="true" accept="application/pdf" previewWidth="0" :maxFileSize="10000000" chooseLabel="PDF auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen" class="cursor-pointer">
                    <template #empty>
                        <div v-if="!loader">
                            <div class="flex justify-content-center p-2"><i class="pi pi-upload h-14 w-14 text-primary-700" style="font-size: 4rem;"></i></div>
                            <div class="flex justify-content-center p-2">Hochladen per Drag & Drop</div>
                        </div>
                        <div class="flex justify-content-center" v-if="loader">
                            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" />
                            Upload läuft, das Fenster wird automatisch geschlossen
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!-------------- PDF Upload Dialog ----------------->
    <!-------------------------------------------------->
</template>