<script setup>
import { ref, onMounted, watch, defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const bexio_api_key = ref()
const company_profile = ref({})
const channel = ref()
const import_data_report = ref({})

// on page load...
onMounted( async () => {
    // Get Inbox Data
    init_data()
})

const props = defineProps({
  prop_channel: { type: String}
})

const init_data = async () => {
    channel.value = props.prop_channel
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/get-system", {channel: channel.value})
    .then(response => {
        if(response.data.status == "success"){
            company_profile.value = response.data.company_profile
            console.log("company_profile", company_profile.value)
        }
        else {
            company_profile.value = null
        }
    })
}

const import_bexio_data = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/import-bexio-data", {channel: channel.value})
        .then(response => {
            import_data_report.value = response.data
            console.log("import_data_report", import_data_report.value)
            toast.add({severity:'success', summary: 'Datenimport', detail: 'Daten wurden erfolgreich importiert', life: 3000})
    })
}

const update_bexio_api_key = () => {
    channel.value = props.prop_channel
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/krediflow/update-bexio-api-key", {channel: channel.value, bexio_api_key: bexio_api_key.value})
    .then(response => {
        if(response.data.status == "success"){
            init_data()
            toast.add({severity:'success', summary: 'API-Key aktualisiert', detail: 'API-Key wurde erfolgreich aktualisiert', life: 3000})
        }
    })
}

watch (props, () => {
    init_data()
})

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <div class="col-12 formgrid grid">
        <div class="field col-12 mt-3">
            <Fieldset legend="Status" class="p-2">
                <InlineMessage v-if="company_profile" severity="success" class="w-full justify-content-start">Bexio Konto ist Verbunden</InlineMessage>
                <InlineMessage v-if="!company_profile" severity="error" class="w-full justify-content-start">Bexio Konto ist nicht Verbunden</InlineMessage>
                <Fieldset v-if="company_profile" legend="Konto" class="mt-2 p-2">
                    <div class="flex flex-wrap">
                        <div class="flex align-items-center justify-content-center mr-4">
                            <img v-if="company_profile.logo_base64" :src="'data:image/png;base64, '+ company_profile.logo_base64" />
                        </div>
                        <div class="no-flex align-items-start justify-content-start">
                            <div class="font-bold w-full">{{ company_profile.name }}</div>
                            <div class="w-full">{{ company_profile.address }}</div>
                            <div class="w-full">{{ company_profile.postcode }}, {{ company_profile.city }}</div>
                        </div>
                    </div>
                </Fieldset>
            </Fieldset>
        </div>
        <div class="field col-12 mt-3">
            <Fieldset legend="Datenimport" class="p-2">
                <Button :disabled="!company_profile" @click="import_bexio_data" v-tooltip.bottom="'Daten aus Bexio aktualisieren'" label="Bexio Synchronisieren" class="mr-2 p-button" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-sync" />
                <div class="mt-2">
                    <div v-for="(import_data, key) in import_data_report" :key="key" class="p-2">
                        <span class="font-bold p-2">{{key}}</span>
                        <ul class="ml-3">
                            <li v-for="(item, key) in import_data" :key="key">{{key}}: {{item}}</li>
                        </ul>
                    </div>
                </div>
            </Fieldset>
        </div>
        <div class="field col-12 mt-4">
            <Fieldset legend="API-Schlüssel" class="p-2">
                <Inplace :closable="true" :pt="{ display: { class: 'bg-primary' }}">
                    <template #display>
                        <span v-if="!company_profile">Bexio-API-Schlüssel hinterlegen</span>
                        <span v-if="company_profile">Bexio-API-Schlüssel ändern</span>
                    </template>
                    <template #content>
                        <div class="flex align-items-start flex-wrap">
                            <div class="mr-2" style="min-width: 600px;">
                                <span class="p-float-label">
                                    <InputText type="password" id="bexio_api_key" v-model="bexio_api_key" :feedback="false" class="w-full" />
                                    <label for="bexio_api_key">Bexio-API-Schlüssel</label>
                                </span>
                                <small class="mt-2">Hier findest du die API-Key in Bexio: <a class="font-bold" href="#">Anleitung</a></small>
                            </div>
                            <div>
                                <Button @click="update_bexio_api_key()" :disabled="!bexio_api_key" label="API-Key speichern" class="w-auto mr-2 mb-1 p-button" icon="pi pi-save" />
                            </div>
                        </div>
                    </template>
                </Inplace>
            </Fieldset>
        </div>
    </div>
</template>